var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{staticStyle:{"position":"relative"},attrs:{"flat":""}},[(_vm.data.length > 0)?_c('v-card',{staticClass:"pa-5 header-major-wrapper"},[_c('v-row',{staticClass:"rounded caption",attrs:{"no-gutters":"","justify":"space-between","align":"center"}},[_c('v-tooltip',{attrs:{"bottom":_vm.$vuetify.breakpoint.xsOnly,"right":!_vm.$vuetify.breakpoint.xsOnly},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center"},'div',attrs,false),on),[_c('v-switch',{staticClass:"mt-0",attrs:{"label":_vm.$t('major.all_major'),"inset":"","dense":"","hide-details":""},on:{"change":_vm.setAllMajor},model:{value:(_vm.allMajor),callback:function ($$v) {_vm.allMajor=$$v},expression:"allMajor"}}),_c('span',{staticStyle:{"display":"none"},attrs:{"id":"loadingCheckAll"}},[_vm._v("Loading ...")])],1)]}}],null,false,2341498060)},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t('major.select_all_major', {
              allMajor: `<span class='font-weight-bold font-italic'>${_vm.$t(
                'major.all_major'
              )}</span>`
            })
          )}})]),_c('v-spacer'),_c('v-btn',{staticClass:"primary white--text",attrs:{"depressed":""},on:{"click":_vm.setMajors}},[_vm._v(_vm._s(_vm.$t("app.save")))])],1)],1):_c('v-sheet',{staticClass:"d-flex justify-center align-center",staticStyle:{"width":"100%","min-height":"85vh"}},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"mb-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("major.major_not_found"))+" ")]),_c('v-btn',{attrs:{"dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.modalFormHandler('add', true)}}},[_vm._v(" "+_vm._s(_vm.$t("major.form_title_add"))+" ")])],1)]),_c('div',{},_vm._l((_vm.data),function(d){return _c('v-card',{key:d.id,staticClass:"pa-3 mb-2 grey lighten-5 wrapper-major",attrs:{"flat":""}},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.$t("major.major"))+": "),_c('b',{staticClass:"ml-2"},[_vm._v(_vm._s(d.major))]),_c('v-switch',{staticClass:"mt-0 ml-2",attrs:{"inset":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.setAllExpertise(d)}},model:{value:(d.selected_major),callback:function ($$v) {_vm.$set(d, "selected_major", $$v)},expression:"d.selected_major"}}),_c('v-spacer'),(!_vm.haveMultiMajor)?_c('v-btn',{attrs:{"fab":"","depressed":"","color":"primary","x-small":""},on:{"click":function($event){return _vm.modalFormHandler('edit', true, {
                  major: d
                })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"12"}},_vm._l((d.reference),function(r,ri){return _c('v-data-table',{key:ri,staticClass:"mb-2",attrs:{"item-key":"expertise_competency_id","headers":[
              {
                text: _vm.$t('major.expertise_program'),
                value: 'expertise_program',
                width: 250
              },
              {
                text: _vm.$t('major.expertise_competency'),
                value: 'expertise_competency'
              },
              !d.is_master && {
                text: _vm.$t('app.action'),
                value: 'action',
                width: 150
              }
            ],"items":r.expertise_competency,"hide-default-footer":"","show-select":""},on:{"change":function($event){return _vm.selectHandler(r)}},scopedSlots:_vm._u([{key:`item.expertise_program`,fn:function(){return [_vm._v(" "+_vm._s(r.expertise_program)+" ")]},proxy:true},{key:`item.expertise_competency`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.expertise_competency)+" ")]}},{key:`item.action`,fn:function({ item }){return [_c('v-btn',{attrs:{"fab":"","depressed":"","color":"primary","x-small":""},on:{"click":function($event){return _vm.modalFormHandler('edit', true, {
                    major: d,
                    expertise: r,
                    competency: item
                  })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"fab":"","depressed":"","color":"error","x-small":""},on:{"click":function($event){return _vm.modalFormHandler('delete', false, {
                    major: d,
                    expertise: r,
                    competency: item
                  })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(r.selected_expertise),callback:function ($$v) {_vm.$set(r, "selected_expertise", $$v)},expression:"r.selected_expertise"}})}),1)],1)],1)}),1),_c('MajorForm',{attrs:{"modal":_vm.openForm,"formType":_vm.formType,"formData":_vm.formData,"haveMultiMajor":_vm.haveMultiMajor,"closeModal":evt => _vm.modalFormHandler(evt, false)}}),_c('ModalConfirm',{attrs:{"close":() => (this.deleteConfirm = false),"isOpen":_vm.deleteConfirm,"save":_vm.deleteMajor,"loading":_vm.loadingDeleteMajor}}),(_vm.data.length > 0)?_c('v-slide-y-transition',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"position":"fixed","bottom":"20px","right":"20px"},attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.modalFormHandler('add', true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,2652091149)},[_c('span',[_vm._v(_vm._s(_vm.$t("major.form_title_add")))])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }