<template>
  <v-dialog v-model="modal" width="500">
    <v-card>
      <v-card-title class="text-h6">
        {{
          formType == "edit"
            ? $t("major.form_title_edit")
            : $t("major.form_title_add")
        }}
        <v-spacer></v-spacer>
        <v-icon @click="closeModal('close', false)">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="majorForm" lazy-validation>
          <v-row class="mt-4">
            <v-col cols="12">
              <v-text-field
                v-model="form.major"
                :rules="[v => !!v || $t('app.required')]"
                :label="$t('major.major')"
                name="major"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
            <v-col v-if="haveMultiMajor" cols="12">
              <v-text-field
                v-model="form.expertise_program"
                :rules="[v => !!v || $t('app.required')]"
                :label="$t('major.expertise_program')"
                name="expertise_program"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
            <v-col v-if="haveMultiMajor" cols="12">
              <v-text-field
                v-model="form.expertise_competency"
                :rules="[v => !!v || $t('app.required')]"
                :label="$t('major.expertise_competency')"
                name="expertise_competency"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="primary" text @click="closeModal('close', false)">
          {{ $t("app.cancel") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="gradient-primary white--text"
          :loading="loadingSave"
          @click="save()"
        >
          {{ $t("app.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { add_master_major, edit_master_major } from "@/api/admin/master/major";

export default {
  props: {
    modal: Boolean,
    closeModal: Function,
    formData: Object,
    formType: String,
    haveMultiMajor: Boolean
  },
  data() {
    return {
      loadingSave: false,
      form: {
        major: "",
        expertise_program: "",
        expertise_competency: ""
      }
    };
  },
  watch: {
    modal(n) {
      if (this.$refs.majorForm) this.$refs.majorForm.reset();
      if (n && this.formType == "edit") {
        this.$nextTick(() => {
          this.form = this.formData;
        });
      }
    }
  },
  methods: {
    save() {
      if (this.$refs.majorForm.validate()) {
        this.loadingSave = true;
        if (this.formType === "add") {
          add_master_major(this.form)
            .then(res => {
              if (res.data.code) this.closeModal("save", false);

              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.code
                  ? this.$i18n.t("major.success_add")
                  : res.data.message,
                color: res.data.code ? "success" : "error"
              });
              this.loadingSave = false;
            })
            .catch(() => (this.loadingSave = false));
        } else {
          edit_master_major(this.form)
            .then(res => {
              if (res.data.code) this.closeModal("save", false);

              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.code
                  ? this.$i18n.t("major.success_edit")
                  : res.data.message,
                color: res.data.code ? "success" : "error"
              });
              this.loadingSave = false;
            })
            .catch(() => (this.loadingSave = false));
        }
      }
    }
  }
};
</script>
