<template>
  <v-sheet flat style="position: relative">
    <v-card v-if="data.length > 0" class="pa-5 header-major-wrapper">
      <!-- <v-row no-gutters align="center" class="mb-4">
        Pastikan pilihan undang-undang sudah benar
        <v-spacer></v-spacer>
        <v-select label="Undang2" dense outlined hide-details></v-select>
      </v-row> -->

      <v-row
        no-gutters
        class="rounded caption"
        justify="space-between"
        align="center"
      >
        <v-tooltip
          :bottom="$vuetify.breakpoint.xsOnly"
          :right="!$vuetify.breakpoint.xsOnly"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex align-center" v-bind="attrs" v-on="on">
              <v-switch
                v-model="allMajor"
                :label="$t('major.all_major')"
                inset
                dense
                hide-details
                class="mt-0"
                @change="setAllMajor"
              ></v-switch>
              <span id="loadingCheckAll" style="display: none"
                >Loading ...</span
              >
            </div>
          </template>
          <span
            v-html="
              $t('major.select_all_major', {
                allMajor: `<span class='font-weight-bold font-italic'>${$t(
                  'major.all_major'
                )}</span>`
              })
            "
          ></span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-btn class="primary white--text" depressed @click="setMajors">{{
          $t("app.save")
        }}</v-btn>
      </v-row>
    </v-card>
    <v-sheet
      v-else
      style="width: 100%; min-height: 85vh"
      class="d-flex justify-center align-center"
    >
      <div class="text-center">
        <div class="mb-2 font-weight-bold">
          {{ $t("major.major_not_found") }}
        </div>
        <v-btn
          dark
          small
          color="primary"
          @click="modalFormHandler('add', true)"
        >
          {{ $t("major.form_title_add") }}
        </v-btn>
      </div>
    </v-sheet>
    <div class="">
      <v-card
        v-for="d in data"
        :key="d.id"
        flat
        class="pa-3 mb-2 grey lighten-5 wrapper-major"
      >
        <v-row class="mt-2">
          <v-col cols="12" class="mb-2">
            <div class="d-flex align-center">
              {{ $t("major.major") }}: <b class="ml-2">{{ d.major }}</b>

              <v-switch
                v-model="d.selected_major"
                inset
                dense
                hide-details
                class="mt-0 ml-2"
                @change="setAllExpertise(d)"
              ></v-switch>

              <v-spacer></v-spacer>
              <v-btn
                v-if="!haveMultiMajor"
                fab
                depressed
                color="primary"
                x-small
                @click="
                  modalFormHandler('edit', true, {
                    major: d
                  })
                "
                ><v-icon small>mdi-pencil</v-icon></v-btn
              >
            </div>
          </v-col>
          <v-col cols="12">
            <v-data-table
              v-for="(r, ri) in d.reference"
              :key="ri"
              v-model="r.selected_expertise"
              item-key="expertise_competency_id"
              :headers="[
                {
                  text: $t('major.expertise_program'),
                  value: 'expertise_program',
                  width: 250
                },
                {
                  text: $t('major.expertise_competency'),
                  value: 'expertise_competency'
                },
                !d.is_master && {
                  text: $t('app.action'),
                  value: 'action',
                  width: 150
                }
              ]"
              :items="r.expertise_competency"
              hide-default-footer
              show-select
              class="mb-2"
              @change="selectHandler(r)"
            >
              <template v-slot:[`item.expertise_program`]>
                {{ r.expertise_program }}
              </template>
              <template v-slot:[`item.expertise_competency`]="{ item }">
                {{ item.expertise_competency }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn
                  fab
                  depressed
                  color="primary"
                  x-small
                  @click="
                    modalFormHandler('edit', true, {
                      major: d,
                      expertise: r,
                      competency: item
                    })
                  "
                  ><v-icon small>mdi-pencil</v-icon></v-btn
                >
                <v-btn
                  fab
                  depressed
                  color="error"
                  class="ml-2"
                  x-small
                  @click="
                    modalFormHandler('delete', false, {
                      major: d,
                      expertise: r,
                      competency: item
                    })
                  "
                  ><v-icon small>mdi-delete</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <!-- <img src="https://asianparent-assets-id.dexecure.net/wp-content/uploads/sites/24/2018/09/motivasi-belajar-anak.jpg?resize=h412" alt=""> -->

    <MajorForm
      :modal="openForm"
      :formType="formType"
      :formData="formData"
      :haveMultiMajor="haveMultiMajor"
      :closeModal="evt => modalFormHandler(evt, false)"
    />

    <ModalConfirm
      :close="() => (this.deleteConfirm = false)"
      :isOpen="deleteConfirm"
      :save="deleteMajor"
      :loading="loadingDeleteMajor"
    />

    <v-slide-y-transition v-if="data.length > 0">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            dark
            small
            color="primary"
            style="position: fixed; bottom: 20px; right: 20px;"
            @click="modalFormHandler('add', true)"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("major.form_title_add") }}</span>
      </v-tooltip>
    </v-slide-y-transition>
  </v-sheet>
</template>

<script>
import MajorForm from "./majorForm";
import ModalConfirm from "@/components/ModalConfirm";

import {
  get_majors,
  set_master_major,
  delete_master_major
} from "@/api/admin/master/major";

export default {
  components: { MajorForm, ModalConfirm },
  data() {
    return {
      allMajor: false,
      openForm: false,
      formDataDelete: {},
      deleteConfirm: false,
      loadingDeleteMajor: false,
      formType: "add",
      formData: {},
      data: []
    };
  },
  computed: {
    haveMultiMajor() {
      const listLevel = ["SMK", "MAK"];
      let haveMultiMajor = false;
      if (listLevel.find(e => e == this.$store.getters.g_school.level)) {
        haveMultiMajor = true;
      }
      return haveMultiMajor;
    }
  },
  created() {
    this.getMajors();
  },
  methods: {
    deleteMajor() {
      this.loadingDeleteMajor = true;
      delete_master_major(this.formDataDelete)
        .then(res => {
          this.deleteConfirm = false;
          this.getMajors();
          this.$store.commit("CALL_SNACKBAR", {
            msg: res.data.code
              ? this.$i18n.t("major.success_delete")
              : res.data.message,
            color: res.data.code ? "success" : "error"
          });
          this.loadingDeleteMajor = false;
        })
        .catch(() => (this.loadingDeleteMajor = false));
    },
    async setMajors() {
      this.$store.commit("TOGGLE_FULL_LOADING");
      let data = JSON.parse(JSON.stringify(this.data));
      await data.map(r => {
        if (r.reference) {
          r.reference.map(ref => {
            ref.expertise_competency.map(e => {
              if (
                ref.selected_expertise.find(
                  se => se.expertise_competency_id == e.expertise_competency_id
                )
              ) {
                e.is_used = true;
              } else {
                e.is_used = false;
              }
            });
          });
        } else {
          r.is_used = r.selected_major;
        }
      });

      set_master_major({ data: data })
        .then(res => {
          this.$store.commit("CALL_SNACKBAR", {
            msg: res.data.code
              ? this.$i18n.t("major.success_setmajor")
              : res.data.message,
            color: res.data.code ? "success" : "error"
          });
          this.$store.commit("TOGGLE_FULL_LOADING");
        })
        .catch(() => this.$store.commit("TOGGLE_FULL_LOADING"));
    },
    getMajors() {
      this.$store.commit("TOGGLE_FULL_LOADING");
      get_majors()
        .then(res => {
          if (res.data.code) {
            let mapingData = [];
            res.data.data.map((d1, d1i) => {
              if (d1.reference) {
                mapingData.push({
                  ...d1,
                  reference: [],
                  selected_major: false
                });
                d1.reference.map(async (d2, d2i) => {
                  mapingData[d1i].reference.push({
                    ...d2,
                    selected_expertise: []
                  });
                  await d2.expertise_competency.map(d3 => {
                    if (d3.is_used && mapingData[d1i].reference[d2i]) {
                      mapingData[d1i].reference[d2i].selected_expertise.push(
                        d3
                      );

                      if (
                        mapingData[d1i].reference[d2i].selected_expertise
                          .length == d2.expertise_competency.length
                      ) {
                        mapingData[d1i].selected_major = true;
                      } else {
                        mapingData[d1i].selected_major = false;
                      }
                    }
                  });
                });
              } else {
                mapingData.push({
                  ...d1,
                  selected_major: d1.is_used
                });
              }
            });

            this.data = mapingData;

            this.checkSelectAllMajor();
            this.$store.commit("TOGGLE_FULL_LOADING");
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
            this.$store.commit("TOGGLE_FULL_LOADING");
          }
        })
        .catch(() => this.$store.commit("TOGGLE_FULL_LOADING"));
    },
    modalFormHandler(param, value, data) {
      this.formData = {};
      switch (param) {
        case "edit":
          if (this.haveMultiMajor) {
            this.formData = {
              major: data.major.major,
              major_id: data.major.major_id,
              expertise_program: data.expertise.expertise_program,
              expertise_program_id: data.expertise.expertise_id,
              expertise_competency_id: data.competency.expertise_competency_id,
              expertise_competency: data.competency.expertise_competency
            };
          } else {
            this.formData = {
              major: data.major.major,
              major_id: data.major.major_id
            };
          }
          break;
        case "delete":
          if (this.haveMultiMajor) {
            this.formDataDelete = {
              major: data.major.major,
              major_id: data.major.major_id,
              expertise_program: data.expertise.expertise_program,
              expertise_program_id: data.expertise.expertise_id,
              expertise_competency_id: data.competency.expertise_competency_id,
              expertise_competency: data.competency.expertise_competency
            };
          } else {
            this.formDataDelete = {
              major: data.major.major,
              major_id: data.major.major_id
            };
          }
          this.deleteConfirm = true;
          break;
        case "save":
          this.getMajors();
          break;
        default:
          break;
      }
      this.formType = param;
      this.openForm = value;
      this.checkSelectAllMajor();
    },
    setAllMajor() {
      const loading = document.getElementById("loadingCheckAll");
      loading.style.display = "block";

      this.data.map(d1 => {
        d1.selected_major = this.allMajor;
        d1.is_used = this.allMajor;
        if (d1.reference) {
          d1.reference.map(d2 => {
            d2.selected_expertise = this.allMajor
              ? d2.expertise_competency
              : [];
            d2.expertise_competency.map(d3 => {
              d3.is_used = this.allMajor;
            });
          });
        }
      });
      loading.style.display = "none";
    },
    async setAllExpertise(data) {
      data.is_used ? (data.is_used = data.selected_major) : "";
      if (data.reference) {
        await data.reference.map(r => {
          r.selected_expertise = data.selected_major
            ? r.expertise_competency
            : [];
          r.expertise_competency.map(e => {
            e.is_used = data.selected_major;
          });
        });
      }

      this.checkSelectAllMajor();
    },
    async checkSelectAllMajor() {
      let isUsed = 0;
      await this.data.map(d => {
        if (d.selected_major) isUsed++;
      });
      this.allMajor = false;
      if (isUsed === this.data.length && this.data.length > 0)
        this.allMajor = true;
    },
    selectHandler(data) {
      console.log(data);
      this.checkSelectAllMajor();
    }
  }
};
</script>

<style lang="scss" scoped>
.header-major-wrapper {
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 5;
  width: 100%;
  position: sticky;
}
.wrapper-major {
  &:first-child {
    margin-top: 10px;
  }
  &:last-child {
    margin-bottom: 40px !important;
  }
}
</style>
