import request from "@/utils/request";

export function get_majors() {
  return request({
    method: "post",
    url: "major/master/get"
  });
}

export function add_master_major(data) {
  return request({
    method: "post",
    url: "major/master/add",
    data: data
  });
}

export function edit_master_major(data) {
  return request({
    method: "post",
    url: "major/master/edit",
    data: data
  });
}

export function delete_master_major(data) {
  return request({
    method: "post",
    url: "major/master/delete",
    data: data
  });
}

export function set_master_major(data) {
  return request({
    method: "post",
    url: "major/institution/set",
    data: data
  });
}
